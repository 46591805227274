const AcLoader = () => {
  return (
    <div className='ac-loader ac-loader--primary'>
      <span className='ac-loader-dot'></span>
      <span className='ac-loader-dot'></span>
      <span className='ac-loader-dot'></span>
    </div>
  );
};

export default AcLoader;
